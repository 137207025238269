<template>
	<div class="jobfair">
		<div class="position-content">
			<div class="position-tab-title">
				<div class="position-tab">
					<div class="sort_order">
						<div class="position-tab-a" v-for="(Sitem, Sindex) in sortList" :key="Sindex"
							v-if="Sitem.data.length>0">
							<div class="title">{{ Sitem.name }}：</div>
							<div class="info flex1">
								<div v-for="(Szitem, Szindex) in Sitem.data" :key="Szindex"
									@click="SelectTap(Sindex, Szindex)" :class="[
										'info-a',
										Sitem.clcik == Szitem.value ? 'info-Select' : '',
									]">
									{{ Szitem.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="job-content">
			<div class="recruit">
				<div class="recruit_item">
					<div class="ibox" v-for="item in activitydata1" :key="item.id"
						@click="goto('/jobfairdetail', { id: item.id })">
						<div class="img"><img :src="setcompanylogo(item.cPicName)" alt="" /></div>
						<div class="img_information">
							<div class="information">
								<div style="margin-bottom:6px;color: #010101;height: 60px;">{{ item.cZph }}</div>
								<div>活动时间：{{utils.timeFormat(item.cZphDate,1) }} 至
									{{utils.timeFormat(item.cZphEndDate,1) }}
								</div>
								<div>主办单位：{{ item.cZhuban?item.cZhuban:'临海市人力资源和社会保障局' }}</div>
							</div>
						</div>
						<div class="sign_up">
							<img :src="require('../../assets/img/jobfair/'+(item.czt!=4?1:4)+'.png')">
						</div>
					</div>
				</div>
			</div>
			<cu-pager :form.sync="selfrom" :total="total" @change="handleCurrentChange" @sizeChange="handleSizeChange">
			</cu-pager>
		</div>
	</div>
</template>
<script>
	import {
		Getjobfairlist
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import CuPager from '../../components/CuPager.vue';
	export default {
		components: {
			CuPager
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				selfrom: {
					KW: "",
					pageNumber: 1,
					pageSize: 10,
				},
				input: "",
				user: {},
				val: "",
				total: 0,
				activitydata: [],
				activitydata1: [],
				isActive: false,
				scenedata: [],
				sortList: [{
					clcik: "",
					name: "招聘会类型",
					data: [{
							name: "全部",
							value: ""
						},
						{
							name: "普通招聘会",
							value: "0"
						},
						// {
						// 	name: "大型招聘会",
						// 	value: "1"
						// },
						// {
						// 	name: "赴外招聘会",
						// 	value: "2"
						// },
						{
							name: "专场招聘会",
							value: "99"
						},
					],
				}, {
					clcik: "",
					name: "当前状态",
					data: [{
							name: "全部",
							value: ""
						},
						{
							name: "准备中",
							value: "0"
						},
						{
							name: "招聘中",
							value: "3"
						},
						{
							name: "招聘结束",
							value: "4"
						},
					],
				}],
			};
		},
		created() {
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
			}
			var type = this.$route.query.type;
			if(!isnull(type)){
				this.sortList[0].clcik = type;
			}
			this.GetList1();
		},
		methods: {
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.GetList1();
			},
			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/activity-1.jpg") :
					this.defimgurl + url;
			},
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			SelectTap(i, j) {
				this.sortList[i].clcik = this.sortList[i].data[j].value;
				this.selfrom.pageNumber = 1;
				this.GetList1();
			},
			GetList1() {
				var _this = this;
				_this.selfrom.field1 = _this.sortList[0].clcik;
				_this.selfrom.types = _this.sortList[1].clcik;
				_this.selfrom.showLoadType = ".activity-cell";
				Getjobfairlist(_this.selfrom).then((res) => {
					if (res.success) {
						_this.total = res.data.total;
						_this.activitydata1 = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.GetList1();
			}
		},
		computed: {
			listTemp: function() {
				var companydata = this.companydata;
				var arrTemp = [];
				var index = 0;
				var sectionCount = 8;
				for (var i = 0; i < companydata.length; i++) {
					index = parseInt(i / sectionCount);
					if (arrTemp.length <= index) {
						arrTemp.push([]);
					}
					arrTemp[index].push(companydata[i]);
				}
				return arrTemp;
			},
		},
		mounted() {},
	};
</script>
<style lang="scss" scoped>
	.banner {
		width: 100%;
		height: 360px;
		padding: 0;
		margin: 0px;
		margin-bottom: 20px;
		position: relative;
		background: url(../../assets/img/activity.png) top center no-repeat;

		.banner-img {
			width: 100%;
			height: 360px;
			margin: 0px auto;
			padding: 0;
			background: url(../../assets/img/activity.png) top center no-repeat;
		}
	}

	.position-content {
		position: relative;
		width: 1200px;
		margin: 0px auto;
		padding: 20px 0;

		.position-tab-title {
			background: #fff;
			padding: 20px 20px 1px 20px;

			.position-tab-a {
				display: flex;
				font-size: 14px;
				margin-bottom: 20px;

				.title {
					font-weight: bold;
					color: #333;
					padding: 5px 10px;
					min-width: 90px;
				}

				.info {
					span {
						margin: 0 20px;
					}

					.info-a {
						cursor: pointer;
						padding: 5px 10px;
						margin-right: 10px;
					}

					.info-Select {
						color: #fff;
						background-color: #2778f8;
						border-radius: 4px;
					}
				}

				.el-dropdown-link {
					cursor: pointer;
				}
			}
		}
	}


	.recruit {
		width: 1200px;
		margin: 0px auto;

		.recruit_item {
			margin: 0 -10px;

			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.ibox {
				cursor: pointer;
				margin: 10px;
				width: 590px;
				height: 160px;
				background-color: #fff;
				padding: 20px;
				box-sizing: border-box;
				display: flex;
				position: relative;
			}

			.img {
				width: 200px;
				height: 120px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.img_information {
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: rgba(255, 255, 255, 100);

				.information {
					color: #898989;
					font-size: 14px;
					margin-left: 14px;
				}

			}

			.sign_up {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 60px;
				height: 60px;

				img {
					width: 100%;
					height: 100%
				}
			}
		}
	}
</style>
